import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {calendarDate} from 'shared/tools';
import {QuickTip, Table} from 'components';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const COLUMNS = [
  {
    header: 'nmbr.updates.directory.Effective Date',
    width: 3,
    render: model => calendarDate(model.effectiveFrom)
  },
  {
    header: 'nmbr.updates.directory.Job Title',
    width: 3,
    attribute: 'title'
  },
  {
    header: 'nmbr.updates.directory.Pay Rate',
    width: 3,
    render: model => t(
      'nmbr.updates.directory.SALARY',
      {
        value: model.formattedValue,
        frequency: t(`nmbr.updates.directory.pay_frequencies.${model.type}`)
      }
    )
  },
  {
    header: 'nmbr.updates.directory.Hours',
    width: 3,
    attribute: 'expectedHoursPerWeek'
  }
];

const PayRateTable = observer(({nmbrUpdate}) => {
  if (!(nmbrUpdate.hasFieldUpdate('pay_rates') || nmbrUpdate.hasFieldUpdate('failed_pay_rates') || nmbrUpdate.hasFieldUpdate('retroactive_pay_rates'))) return null;

  return (
    <div className='space-y'>
      <div className='h3 medium'><FormattedMessage id='nmbr.updates.directory.Pay Rates'/></div>
      {nmbrUpdate.hasFieldUpdate('pay_rates') && <Table
        columns={COLUMNS}
        models={_.filter(nmbrUpdate.fieldUpdates, {updateType: 'pay_rates'})}
        showLinks={false}
      />}
      {nmbrUpdate.hasFieldUpdate('failed_pay_rates') && <QuickTip
        trait='error'
        header={t('nmbr.updates.directory.Some pay rates could not be processed')}
        paragraphs={[
          t('nmbr.updates.directory.UNSYNCED_PAY_RATES_WARNING'),
          <ul className='ml2'>
            {nmbrUpdate.fieldUpdatesForType('failed_pay_rates').map(field_update => <li>
              {t('nmbr.updates.directory.EMPLOYMENT_RECORD_ERROR', {
                effectiveDate: calendarDate(field_update.effectiveDate),
                recordType: t(`nmbr.updates.directory.record_types.${field_update.recordType}`),
                errors: field_update.translateErrors ? field_update.errors.map(error => t(`nmbr.updates.directory.errors.${error}`)).join(' ') : field_update.errors.join(' ')
              })}
            </li>)}
          </ul>
        ]}/>}
        {nmbrUpdate.hasFieldUpdate('retroactive_pay_rates') && <QuickTip
          trait='caution'
          header={t('nmbr.updates.directory.Retroactive Changes Not Applied')}
          paragraphs={[t(
            'nmbr.updates.directory.RETROACTIVE_CHANGES_WARNING',
            {
              effectiveDate: calendarDate(nmbrUpdate.fieldUpdatesForType('retroactive_pay_rates')[0].effectiveDate),
              cutoffDate: calendarDate(nmbrUpdate.fieldUpdatesForType('retroactive_pay_rates')[0].cutoffDate)
            }
          )]}
        />}
    </div>
  );
});

export default PayRateTable;
