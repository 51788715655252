const endpoints = {
  ADMIN_INVITATIONS: '/admin_invitations',
  PASSWORD_RESETS: '/password_resets',
  USERS: {
    ALL: '/users',
    ACTIVE: '/users/active',
    ACTIVE_WITH_DOMAIN_MISMATCH: {
      with(provider) {
        return `/users/active_with_domain_mismatch?provider=${provider}`;
      }
    },
    STOP_IMPERSONATING: '/users/stop_impersonating'
  },
  USER_DIRECTORY: '/user_directory',
  EMPLOYEES: {
    ALL: '/employees',
    MANAGERS: '/employees?filter[managers]=true',
    TERMINATED: '/employees?filter[terminated]=true&include=location,department'
  },
  EMPLOYEE_DIRECTORY: {
    SUMMARIES: '/employee_directory',
    UNPAGINATED: '/employee_directory/unpaginated',
    NAVIGATION: '/employee_directory/navigation'
  },
  EMPLOYEE_DETAILS: {
    with(employeeId) {
      return `/employees/${employeeId}?include=location,department,bank_account,bank_account.cheque,employee_custom_fields.options,employee_custom_fields.custom_field_group,employee_benefit_enrollments,employee_insurance_numbers,employee_insurance_numers.company_insurance_carrier,onboard`;
    }
  },
  ATTACHED_DOCUMENT: {
    with(id) {
      return `/attached_documents/${id}`;
    }
  },
  EMPLOYEE_DOCUMENTS: {
    PRESIGNER: {
      with(employeeId) {
        return `/employees/${employeeId}/documents/presigner`;
      }
    },
    with(employeeId) {
      return `/employees/${employeeId}/documents`;
    },
    withFilter(employeeId, folderId) {
      return `/employees/${employeeId}/documents?filter[folder_id]=${folderId}`;
    },
    ALL: {
      with(employeeId, category) {
        return `/employees/${employeeId}/documents/all?filter[category]=${category}`;
      }
    },
  },
  EMPLOYEE_FILES: {
    with(fileId) {
      return `/employee_files/${fileId}`;
    }
  },
  CURRENT_COMPANY: '/companies/current',
  COMPANY: {
    with(id) {
      return `/companies/${id}`;
    },
    ENABLE_GOOGLE_SSO: 'companies/enable_google_sso',
    ENABLE_MICROSOFT_SSO: 'companies/enable_microsoft_sso'
  },
  COMPANY_BEGIN_CONFIGURATION: '/companies/begin_configuration',
  COMPANY_REQUEST_PAYROLL_CANCELLATION: '/companies/request_payroll_cancellation',
  COMPANY_REQUEST_SUBSCRIPTION_CANCELLATION: '/companies/request_subscription_cancellation',

  AGENT_OF_RECORD: {
    LETTER: 'agent_of_record_letter'
  },

  PERFORMANCE: {
    CYCLES: 'performance_management/cycles',
    CYCLE_NAVIGATION: '/performance_management/cycles/navigation',
    REVIEWERS: 'performance_management/reviewers',
    REVIEWEES: 'performance_management/reviewees',
    REVIEWEE_CHECKINS: {
      with(employeeId) {
        return `performance_management/reviewees/${employeeId}`;
      }
    },
    CYCLE_REVIEWEES: {
      with(cycleId, revieweeId) {
        return `performance_management/cycles/${cycleId}/reviewees/${revieweeId}`;
      }
    },
    CHECKIN: {
      with(id) {
        return `performance_management/checkins/${id}`;
      }
    },
    CHECKINS: 'performance_management/checkins',
    CYCLE_CHECKIN: {
      with(cycleId) {
        return `performance_management/cycles/${cycleId}/checkin`;
      }
    },
    CYCLE: {
      with(cycleId) {
        return `performance_management/cycles/${cycleId}/`;
      }
    }
  },

  TASKS: {
    ALL: 'tasks',
    CREATE_FROM_TEMPLATES: 'tasks/create_from_templates',
    ONBOARDING: {
      with(employeeId) {
        return `tasks?filter[employee_id]=${employeeId}&filter[task_type]=onboarding`;
      }
    }
  },
  TASK: {
    with(id) {
      return `tasks/${id}`;
    }
  },
  TASK_TEMPLATES: {
    all: 'task_templates',
    DEFAULT_FOR_EMPLOYEE: {
      with(employeeId, templateType) {
        return `task_templates/list_default?employee_id=${employeeId}&template_type=${templateType}`;
      }
    },
    with(templateType, draftEmployeeId) {
      return `task_templates?filter[template_type]=${templateType}&filter[draft_employee_id]=${draftEmployeeId}`;
    },
    withType(templateType, excludedIds = [], excludeManager = false) {
      return `task_templates?filter[template_type]=${templateType}&filter[exclude_manager]=${excludeManager}${excludedIds.map((id) => id ? `&filter[excluded_ids][]=${id}` : '').join('')}`;
    }
  },
  TASK_TEMPLATE: {
    with(id) {
      return `task_templates/${id}`;
    }
  },

  EMPLOYEE: {
    TASKS: {
      with(employeeId) {
        return `employees/${employeeId}/tasks`;
      }
    },
    EMPLOYMENT_RECORDS: {
      with(employeeId) {
        return `employees/${employeeId}/employment_records`;
      }
    },
    CURRENT_EMPLOYMENT_RECORD: {
      with(employeeId) {
        return `employees/${employeeId}/employment_records/current`;
      }
    },
    EMPLOYMENT_HISTORY: {
      with(employeeId) {
        return `employees/${employeeId}/employment_history`;
      },
      NAVIGATION: {
        with(employeeId) {
          return `employees/${employeeId}/employment_history/navigation`;
        }
      }
    },
    BULK_IMPORT: 'employee_import',
    SEND_INVITE: {
      with(employeeId) {
        return `employees/${employeeId}/send_invite`;
      }
    },
    CREATE_FOR_ENROLMENT: 'employees/create_for_enrolment',
    ENROL: {
      with(employeeId) {
        return `employees/${employeeId}/enrol`;
      }
    },
    EMPLOYEE_BENEFIT_ENROLMENTS: {
      with(employeeId) {
        return `employees/${employeeId}/employee_benefit_enrollments`;
      }
    },
    MARK_FOR_UNENROLMENT: {
      with(employeeId) {
        return `employees/${employeeId}/employee_benefit_enrollments/mark_for_unenrolment`;
      }
    },
    CHANGE_ENROLMENTS: {
      with(employeeId) {
        return `employees/${employeeId}/employee_benefit_enrollments/change_enrolments`;
      }
    },
    PLATFORM_USAGE: {
      with(employeeId) {
        return `employees/${employeeId}/platform_usage`;
      }
    }
  },

  TRAINING: {
    PROGRAMS: 'programs',
    NEW_HIRE_PROGRAMS: 'programs/new_hire',
    PROGRAM: {
      with(id) {
        return `programs/${id}`;
      }
    },
    PROGRAM_DUPLICATION: {
      with(id) {
        return `programs/${id}/duplicate`;
      }
    },
    CLOSE: {
      with(id) {
        return `programs/${id}/close`;
      }
    },
    EMPLOYEE_TRAINING_STATUS: {
      with(id) {
        return `training_statuses/${id}`;
      }
    },
    EMPLOYEE_TRAINING_STATUSES: {
      ALL: 'training_statuses',
      with(employeeId) {
        return `employees/${employeeId}/employee_training_statuses`;
      },
      PROGRAM: {
        with(programId) {
          return `training_statuses?filter[status]=all&filter[program]=${programId}`;
        }
      }
    }
  },

  HIRE_FROM_RECRUITING: 'employees/hire_from_recruiting',

  RECRUITING: {
    POSITIONS: {
      APPLIABLE: 'positions/all?filter[status]=appliable',
      COMPLETED: 'positions/all?filter[status]=completed',
      DIRECTORY: 'positions'
    },
    POSITIONS_FOR_REPORT: 'positions/list_for_report',
    POSITIONS_FOR_REFERRALS: 'positions/list_for_referrals?include=location,department',
    POSITION: {
      DUPLICATE: {
        with(id) {
          return `/positions/${id}/duplicate`;
        }
      },
      CANDIDATE_COUNTS_BY_STAGE: {
        with(id) {
          return `/positions/${id}/candidate_counts_by_stage`;
        }
      },
      LIST_TAGGABLE_USERS: {
        with(id) {
          return `/positions/${id}/list_taggable_users`;
        }
      },
      with(id) {
        return `positions/${id}`;
      }
    },
    CANDIDATE_TAGS: 'candidate_tags',
    JOB_SITE: {
      SETTINGS: 'job_site_setting',
      INVALID_INDEED_LOCATIONS: 'job_site_setting/invalid_indeed_locations'
    },
    CANDIDATES: {
      all: 'candidates',
      with(positionId) {
        return `positions/${positionId}/candidates`;
      },
      FILTERABLE: {
        with(positionId) {
          return `positions/${positionId}/candidates/list_for_filter?include=current_stage`;
        }
      },
      REFER: {
        with(positionId) {
          return `positions/${positionId}/candidates/refer`;
        }
      },
      BULK_ACTION: 'candidates/bulk_action'
    },
    CANDIDATE: {
      with(positionId, candidateId) {
        return `positions/${positionId}/candidates/${candidateId}`;
      }
    },
    JOB_APPLICATION: {
      with(slug, positionId) {
        // The job application controller is mounted at root and not api/v#
        return `../../jobs/${slug}/${positionId}/submit`;
      }
    },
    PUBLIC_POSITION: {
      with(slug, positionId) {
        return `../../jobs/${slug}/${positionId}/public`;
      }
    },
    FEEDBACK: {
      NEW: {
        with(positionId, candidateId) {
          return `positions/${positionId}/candidates/${candidateId}/feedback`;
        }
      }
    },
    EMAIL: {
      NEW: {
        with(positionId, candidateId) {
          return `positions/${positionId}/candidates/${candidateId}/email`;
        }
      }
    },
    UPLOAD: {
      NEW: {
        with(positionId, candidateId) {
          return `positions/${positionId}/candidates/${candidateId}/candidate_internal_upload`;
        }
      }
    },
    INTERVIEW_GUIDE_RESPONSE: {
      NEW: {
        with(positionId, candidateId) {
          return `positions/${positionId}/candidates/${candidateId}/interview_guide_response`;
        }
      }
    }
  },

  DEPARTMENTS: 'departments',

  FOLDERS: {
    ALL: 'folders',
    FOR_EMPLOYEE: {
      with(employeeId) {
        return `folders/for_employee/${employeeId}`;
      }
    },
    REORDER: '/folders/reorder'
  },

  COMPANY_EMAIL_TEMPLATES: 'company_email_templates',
  COMPANY_EMAIL_TEMPLATE: {
    with(id) {
      return `/company_email_templates/${id}`;
    }
  },

  OFFER_TEMPLATES: 'offer_templates',
  OFFER_TEMPLATE: {
    with(id) {
      return `/offer_templates/${id}`;
    },
  },

  OFFERS: 'offers',
  OFFER: {
    PUBLIC: {
      with(token) {
        return `offer?token=${token}`;
      },
      ACCEPT: {
        with(token) {
          return `offer/accept?token=${token}`;
        }
      }
    },
    REVOKE: {
      with(id) {
        return `/offers/${id}/revoke`;
      }
    },
    HIRE: {
      with(id) {
        return `/offers/${id}/hire`;
      }
    },
    with(id) {
      return `/offers/${id}`;
    }
  },

  LOCATIONS: {
    ALL: 'locations',
    DIRECTORY: 'locations/directory'
  },
  LOCATION: {
    with(id) {
      return `locations/${id}`;
    }
  },

  SECURE_UPLOAD: {
    PRESIGNER: {
      with(modelType) {
        return `secure_upload/presigner?modelType=${modelType}`;
      }
    },
    NEW: 'secure_upload'
  },

  COMPANY_LINKS: {
    ALL: '/company_links',
    REORDER: '/company_links/reorder'
  },

  COMPANY_FILES: {
    ALL: '/company_files',
    REORDER: '/company_files/reorder',
    with(companyFileFolderId) {
      return `/company_files${companyFileFolderId ? `?company_file_folder_id=${companyFileFolderId}` : ''}`;
    }
  },
  COMPANY_FILE_FOLDERS: {
    ALL: '/company_file_folders',
    REORDER: '/company_file_folders/reorder'
  },
  VISIBILITY_SETTINGS: '/visibility_settings',

  CUSTOM_FIELDS: {
    ALL: '/custom_fields',
    REORDER: '/custom_fields/reorder'
  },

  CUSTOM_FIELD_GROUPS: {
    ALL: '/custom_field_groups',
    REORDER: '/custom_field_groups/reorder',
    with(categories) {
      return `/custom_field_groups?categories=${categories}`;
    }
  },

  TIME_OFF: {
    POLICIES: {
      ALL: 'time_off_policies?include=type_policies,type_policies.type,employees',
      CONFIGURED: 'time_off_policies/configured',
      CONFIGURED_DETAILS: 'time_off_policies/configured_details',
      ELIGIBLE_EMPLOYEES: {
        with(id) {
          return `/time_off_policies/${id}/eligible_employees`;
        }
      }
    },
    POLICY: {
      with(id) {
        return `time_off_policies/${id}`;
      },
      withEmployeeId(employeeId) {
        return `employees/${employeeId}/time_off_policy`;
      },
      UPDATE_DETAILS: {
        with(id) {
          return `time_off_policies/${id}/update_details`;
        }
      },
      UPDATE_TYPES: {
        with(id) {
          return `time_off_policies/${id}/update_types`;
        }
      },
      UPDATE_EMPLOYEES: {
        with(id) {
          return `time_off_policies/${id}/update_employees`;
        }
      },
      COMPLETE: {
        with(id) {
          return `time_off_policies/${id}/complete`;
        }
      }
    },
    UPDATE_POLICY: {
      with({employeeId, effectiveDate}) {
        return `employees/${employeeId}/time_off_policy?effectiveDate=${effectiveDate}`;
      }
    },
    FORECAST_UPDATE_POLICY: {
      with(employeeId) {
        return `employees/${employeeId}/forecast_time_off_policy`;
      }
    },
    ACCOUNTS: {
      with(employeeId) {
        return `employees/${employeeId}/time_off_accounts`;
      },
      PREDICT: {
        with({employeeId, policyId, effectiveDate}) {
          return `employees/${employeeId}/time_off_accounts/predict?policyId=${policyId}&effectiveDate=${effectiveDate}`;
        }
      },
    },
    UPCOMING_HOLIDAYS: {
      with(employeeId) {
        return `employees/${employeeId}/upcoming_holidays`;
      }
    },
    CONFLICTING_REQUESTS: {
      with(employeeId) {
        return `employees/${employeeId}/time_off_requests/conflicting`;
      }
    },
    UPCOMING_REQUESTS: {
      with(employeeId) {
        return `employees/${employeeId}/time_off_requests/upcoming`;
      }
    },
    NEW_TIME_OFF_REQUEST: {
      with({employeeId}) {
        return `employees/${employeeId}/time_off_requests`;
      }
    },
    TYPES: {
      ALL: 'time_off_types',
      REORDER: 'time_off_types/reorder'
    },
    BLOCKED_DATES: {
      ALL: 'time_off_blocked_dates',
      FOR_REQUEST: {
        with({employeeId, typeId, startDate, endDate}) {
          return `time_off_blocked_dates/list_for_request?employee_id=${employeeId}&type_id=${typeId}&start_date=${startDate}&end_date=${endDate}`;
        }
      }
    },
    ACCOUNT_SUMMARIES: 'time_off_account_summaries',
    TRANSACTIONS: {
      with(toDate) {
        return `time_off_transactions?include=created_by_user,time_off_request&to_date=${toDate}`;
      }
    },
    REQUEST: {
      with(id) {
        return `time_off_requests/${id}?include=created_by_user,first_stage_actioned_by_user,second_stage_actioned_by_user,current_assignments,current_assignments.assignee_user,current_assignments.delegated_by_user,currently_delegated_by_user,employee,time_off_type,absences,attachments`;
      }
    },
    REQUESTS: 'time_off_requests?include=created_by_user,first_stage_actioned_by_user,second_stage_actioned_by_user',
    PENDING_REQUESTS: 'time_off_requests/pending?include=time_off_type,employee,current_assignments,current_assignments.assignee_user,current_assignments.delegated_by_user',
    PEOPLE_OFF: {
      with(date) {
        return `time_off_requests/people_off?date=${date}&include=time_off_type,employee`;
      },
    },
    CONCURRENT: {
      with({employeeId, id}) {
        return `employees/${employeeId}/time_off_requests/${id}/concurrent?include=time_off_type,employee`;
      },
      FOR_POLICY_HOLDER: {
        with({employeeId, startDate, endDate}) {
          return `employees/${employeeId}/time_off_requests/concurrent_for_policy_holder?startDate=${startDate}&endDate=${endDate}&include=time_off_type`;
        }
      }
    },
    TYPE_POLICIES: {
      with(id) {
        return {
          confirmationSummary: `time_off_type_policies/${id}/confirmation_summary`
        };
      },
      UPDATE_CONFIRMATION_SUMMARY: {
        with(id) {
          return `time_off_type_policies/${id}/update_confirmation_summary`;
        }
      },
      FORECAST_UPDATE: {
        with({id, employeeId, forecastDate, effectiveDate}) {
          return `time_off_type_policies/${id}/forecast_update?employeeId=${employeeId}&forecastDate=${forecastDate}&effectiveDate=${effectiveDate}`;
        }
      }
    },
    FORECAST_ACCOUNT_BALANCE: {
      with({employeeId, typeId}) {
        return `employees/${employeeId}/time_off_requests/forecast?typeId=${typeId}`;
      },
      FOR_REQUEST: {
        with({employeeId, typeId, date, id}) {
          return `employees/${employeeId}/time_off_requests/${id}/forecast_for_request?date=${date}&typeId=${typeId}`;
        },
      }
    },
    DAYS_OFF: {
      with({employeeId, typeId, startDate, endDate}) {
        return `employees/${employeeId}/time_off_requests/days_off?startDate=${startDate}&endDate=${endDate}&typeId=${typeId}`;
      },
      FOR_REQUEST: {
        with({employeeId, id}) {
          return `employees/${employeeId}/time_off_requests/${id}/days_off_for_request`;
        },
      }
    },
    ALLOWED_TO_DELEGATE: {
      with(employeeId) {
        return `employees/${employeeId}/time_off_requests/allowed_to_delegate`;
      }
    },
    NAVIGATION: '/time_off/navigation'
  },

  TIME_TRACKING: {
    NAVIGATION: '/time_tracking/navigation',
    ENTRIES: {
      with(employeeId) {
        return `/time_tracking/entries?employee_id=${employeeId}`;
      }
    },
    EMPLOYEES: {
      with(payPeriodId) {
        return `/time_tracking/policy_enrolments/enrolled_employees?pay_period_id=${payPeriodId}`;
      }
    },
    ENTRIES_FOR_PAY_PERIOD: {
      with(payPeriodId, employeeId) {
        return `/time_tracking/entries/for_pay_period?pay_period_id=${payPeriodId}&employee_id=${employeeId}`;
      }
    },
    APPROVE: {
      with(payPeriodId, employeeId) {
        return `/time_tracking/entries/approve?pay_period_id=${payPeriodId}&employee_id=${employeeId}`;
      }
    },
    POLICIES: {
      ALL: '/time_tracking/policies',
      with(id) {
        return `/time_tracking/policies/${id}`;
      },
      ELIGIBLE_EMPLOYEES: {
        with(id) {
          return `/time_tracking/policies/${id}/eligible_employees`;
        }
      }
    },
    PROJECTS: {
      ALL: '/time_tracking/projects/all',
      DIRECTORY: '/time_tracking/projects',
      FOR_EMPLOYEE: '/time_tracking/projects/list_for_employee',
      with(id) {
        return `/time_tracking/project/${id}`;
      }
    },
    PAY_SCHEDULES: {
      ALL: '/time_tracking/pay_schedules?include=pay_periods',
      SUMMARY: '/time_tracking/pay_schedules?include=latest_pay_period,current_pay_period',
      DIRECTORY: '/time_tracking/pay_schedules',
      CALCULATE_INITIAL_PERIODS: {
        with(startDate, frequency) {
          return `/time_tracking/pay_schedules/calculate_initial_periods?start_date=${startDate}&frequency=${frequency}`;
        }
      },
      with(id) {
        return `/time_tracking/pay_schedules/${id}`;
      }
    },
    PAY_PERIODS: {
      ALL: '/time_tracking/pay_periods',
      FOR_PAY_SCHEDULE: {
        with(id) {
          return `/time_tracking/pay_schedules/${id}/pay_periods`;
        }
      },
      FOR_EMPLOYEE: {
        with(employeeId) {
          return `/time_tracking/pay_periods?employee_id=${employeeId}`;
        }
      }
    },
    PAYROLL_HOURS: {
      with(payScheduleId, payPeriodId) {
        return `/time_tracking/payroll_hours?pay_schedule_id=${payScheduleId}&pay_period_id=${payPeriodId}`;
      }
    },
    REPORT_DOWNLOADS: {
      with(id) {
        return `/time_tracking/report_downloads/${id}`;
      },
      REQUEST_DOWNLOAD: {
        with(reportType) {
          return `/time_tracking/report_downloads/${reportType}/request_download`;
        }
      }
    }
  },

  HOLIDAYS: {
    ALL: 'holidays',
    FOR_YEAR: {
      with(year) {
        return `holidays?year=${year}`;
      }
    },
    BATCH: 'holidays/batch'
  },

  ROLES: 'roles',
  ROLE: {
    with(roleId) {
      return `roles/${roleId}`;
    }
  },

  COUNTRIES: 'countries',
  SSO: {
    OKTA_CREDENTIALS: 'sso/okta_credentials',
    GOOGLE_CREDENTIALS: 'sso/google_credentials',
    MICROSOFT_CREDENTIALS: 'sso/microsoft_credentials',
    CLEAR_OKTA_CREDENTIALS: 'sso/okta_credentials/clear'
  },
  REGIONS: {
    with(countryId) {
      return `/countries/${countryId}/regions`;
    }
  },

  BILLING: {
    SUBSCRIPTION: '/billing_subscriptions',
    CHARGIFY_DIRECT: {
      with(callId) {
        return `/chargify_direct?call_id=${callId}`;
      }
    },
    UPDATE_CONTACT_DETAILS: '/billing_subscriptions/update_contact_details'
  },
  SESSIONS: {
    new: 'sessions',
    renew: 'sessions/renew'
  },

  MFA: {
    SETUP_SMS: 'mfa/setup_sms',
    SETUP_AUTHENTICATOR: 'mfa/setup_authenticator',
    VERIFY_MFA_METHOD: 'mfa/verify_mfa_method',
    DISCONNECT: 'mfa/disconnect',
    RESET_FOR_USER: 'mfa/reset_for_user'
  },

  EMPLOYEE_CALENDARS: 'employee_calendars',
  CALENDARS: 'calendars',
  CALENDAR: {
    TOGGLE: {
      with(id) {
        return `calendars/${id}/toggle`;
      }
    }
  },
  SEGMENT_CALENDARS: 'segment_calendars',
  SEGMENT_CALENDAR: {
    with(id) {
      return `/segment_calendars/${id}`;
    }
  },
  CALENDAR_SUBSCRIPTIONS: 'calendar_subscriptions',

  NOTES: {
    with(employeeId) {
      return `employees/${employeeId}/notes?include=author`;
    }
  },

  COMPANY_ONBOARD: {
    ADD_EMPLOYEE: '/employees/add_employee',
    RESEND_ACTIVATION_EMAIL: '/users/resend_activation_email'
  },

  GLOBAL_SEARCH: '/global_search',

  COMPANY_DOCUMENTS: {
    all: '/company_documents',
    WITH_CUSTOM_FIELD: {
      with(customFieldId) {
        return `/company_documents/with_custom_field?custom_field_id=${customFieldId}`;
      }
    },
    with(draftEmployeeId) {
      return `/company_documents?filter[draft_employee_id]=${draftEmployeeId}`;
    },
    withEmployee(employeeId) {
      return `/company_documents?filter[employee_id]=${employeeId}`;
    }
  },
  EMPLOYEE_GROUPS: '/employee_groups',
  ASSIGN_COMPANY_DOCUMENT: {
    with(employeeId) {
      return `/company_documents/assign_company_document?employee_id=${employeeId}`;
    }
  },
  TAX_DOCUMENTS: '/company_documents/send_tax_forms',
  COMPANY_DOCUMENT: {
    with(id) {
      return `/company_documents/${id}`;
    }
  },
  DOCUMENT_ACTIONS: {
    with(documentId) {
      return `/company_documents/${documentId}/actions`;
    },
    withEmployee(employeeId) {
      return `/company_documents/employee/${employeeId}/list_for_onboarding_employee`;
    }
  },
  COMPANY_SIGNATURES: '/signatures',
  COMPANY_SIGNATURE: {
    with(id) {
      return `/signatures/${id}`;
    }
  },

  PENDING_DOCUMENTS: '/pending_documents',
  PENDING_DOCUMENT: {
    with(id) {
      return `/pending_documents/${id}`;
    }
  },

  REMINDERS: '/reminder_rules',
  REMINDER: {
    with(id) {
      return `/reminder_rules/${id}`;
    }
  },

  COMPANY_INSURANCE_CARRIERS: {
    WITHOUT_OPTIONAL: '/company_insurance_carriers?filter[optional]=false',
    WITH_OPTIONAL: '/company_insurance_carriers?filter[optional]=true'
  },
  BENEFIT_ENROLMENTS: {
    DIRECTORY: `/benefit_enrolments`,
    ALL: `/benefit_enrolments/all`
  },

  BENEFIT_INFO: {
    with(employeeId) {
      return `/employees/${employeeId}/benefit_info`;
    }
  },

  EMAIL_TEMPLATES: '/email_templates',
  EMAIL_TEMPLATE: {
    with(id) {
      return `/email_templates/${id}`;
    }
  },

  INTERVIEW_GUIDES: '/interview_guides',
  INTERVIEW_GUIDE: {
    with(id) {
      return `/interview_guides/${id}`;
    }
  },

  USER: {
    with(id) {
      return `/users/${id}`;
    }
  },

  PAYROLL_UPDATES: {
    SUBMITTED: '/payroll_updates?status=submitted',
    COMPLETED: '/payroll_updates?status=completed'
  },
  PAYROLL_UPLOADS: '/payroll_uploads',
  PAYROLL_UPLOAD: {
    NEW: '/payroll_uploads'
  },
  PAYROLL_EMPLOYEE_SUMMARIES: '/payroll_employee_summaries',

  PAYROLL: {
    INTERESTED: '/payroll/interested',
    NAVIGATION: '/payroll/navigation'
  },

  PAY_PERIODS: '/pay_periods',
  PAY_PERIOD: {
    CURRENT: '/pay_periods/current',
    WITH_EMPLOYEES: {
      with(id) {
        return `/pay_periods/${id}?include=employees`;
      }
    },
    NEXT_PAY_DATE_PERIOD: '/pay_periods/next_pay_date_period'
  },
  PAYROLL_DIFFS: {
    with(employeeId, payPeriodId) {
      return `/payroll_diffs?filter[employee_id]=${employeeId}&filter[pay_period_id]=${payPeriodId}`;
    }
  },
  CUSTOM_REPORTS: {
    ALL: '/custom_reports',
    SHARED: '/custom_reports/shared'
  },
  CUSTOM_REPORT: {
    with(id) {
      return `/custom_reports/${id}`;
    },
    filterableEmployees(id) {
      return `/custom_reports/${id}/filterable_employees`;
    },
    filterableManagers(id) {
      return `/custom_reports/${id}/filterable_managers`;
    },
    filterableLocations(id) {
      return `/custom_reports/${id}/filterable_locations`;
    },
    filterableDepartments(id) {
      return `/custom_reports/${id}/filterable_departments`;
    }
  },

  REPORT_DOWNLOAD: {
    with(id) {
      return `/report_downloads/${id}`;
    }
  },

  REPORTS: {
    NAVIGATION: '/reports/navigation'
  },

  ANNOUNCEMENTS: '/announcements/',
  ANNOUNCEMENT: {
    with(id) {
      return `/announcements/${id}`;
    },
    publish(id) {
      return `/announcements/${id}/publish`;
    }
  },
  ANNOUNCEMENT_COMMENTS: '/announcements/comments',
  ANNOUNCEMENT_COMMENT: {
    with(id) {
      return `/announcements/comments/${id}`;
    }
  },
  ANNOUNCEMENT_REACTIONS: '/announcements/reactions',
  ANNOUNCEMENT_REACTION: {
    with(id) {
      return `/announcements/reactions/${id}`;
    }
  },
  EMPLOYEE_ANNOUNCEMENTS: `/employee_announcements`,

  REVIEW_TEMPLATES: {
    ALL: '/performance_management/reviews/review_templates',
    PAGINATED: '/performance_management/reviews/review_templates/directory'
  },
  REVIEW_TEMPLATE: {
    DUPLICATE: {
      with(id) {
        return `/performance_management/reviews/review_templates/${id}/duplicate`;
      }
    },
    with(id) {
      return `/performance_management/reviews/review_templates/${id}?include=questions`;
    }
  },

  PERFORMANCE_GOALS: {
    GOAL: {
      with(goalId) {
        return `/performance_management/goals/goals/${goalId}`;
      },
      DUPLICATE: {
        with(goalId) {
          return `/performance_management/goals/goals/${goalId}/duplicate`;
        }
      }
    },
    GOALS: '/performance_management/goals/goals',
    GOALS_FOR_EMPLOYEE: {
      with(employeeId) {
        return `/performance_management/goals/goals?filter[employee_id]=${employeeId}`;
      }
    },
    GOALS_FOR_EMPLOYEE_REVIEWS: {
      with(employeeId) {
        return `/performance_management/goals/goals?filter[employee_id]=${employeeId}&filter[parent_goals_only]=true`;
      }
    },
    PARENT_GOALS_FOR_EMPLOYEE: {
      with(employeeId) {
        return `performance_management/goals/parent_goals/list_for_employee?employee_id=${employeeId}`;
      }
    },
    PARENT_GOALS_FOR_GOAL: {
      with(goalId) {
        return `performance_management/goals/parent_goals/list_for_goal?goal_id=${goalId}`;
      }
    },
    EMPLOYEE_DIRECTORY: '/performance_management/goals/employee_directories',
    EMPLOYEES: '/performance_management/goals/goals/assignable_employees',
    FILTERABLE_EMPLOYEES: '/performance_management/goals/goals/filterable_employees',
    NAVIGATION: '/performance_management/goals/goals/navigation',
    COMPANY_GOALS: '/performance_management/goals/goals/company_goals'
  },

  PERFORMANCE_REVIEWS: {
    CYCLES: {
      ALL: '/performance_management/reviews/cycles/all',
      LAUNCHED: '/performance_management/reviews/cycles/launched',
      DIRECTORY: '/performance_management/reviews/cycles',
      TRENDS: {
        with(groupByEmployees, groupByQuestions) {
          return `/performance_management/reviews/cycles/trends?groupByEmployees=${groupByEmployees}&groupByQuestions=${groupByQuestions}`;
        }
      }
    },
    CYCLE: {
      with(id) {
        return `/performance_management/reviews/cycles/${id}`;
      }
    },
    CYCLE_RESULTS: {
      BAR_GRAPH: {
        with(id) {
          return `/performance_management/reviews/cycles/${id}/cycle_results/bar_graph`;
        }
      },
      DISTRIBUTION: {
        with(id) {
          return `/performance_management/reviews/cycles/${id}/cycle_results/distribution`;
        }
      },
      HEATMAP: {
        with(id) {
          return `/performance_management/reviews/cycles/${id}/cycle_results/heatmap`;
        }
      },
      NINEBOX: {
        with(id, questionId1, questionId2) {
          return `/performance_management/reviews/cycles/${id}/cycle_results/ninebox?questionId1=${questionId1}&questionId2=${questionId2}`;
        }
      }
    },
    CYCLE_WITH_REVIEWEES: {
      with(id) {
        return `/performance_management/reviews/cycles/${id}?include=reviewees,types,types.template`;
      }
    },
    REVIEWS: {
      all(cycleId) {
        return `/performance_management/reviews/cycles/${cycleId}/reviews/all`;
      },
      with(cycleId) {
        return `/performance_management/reviews/cycles/${cycleId}/reviews`;
      },
      FOR_REVIEWEE: {
        with(cycleId, reviewee_id) {
          return `/performance_management/reviews/cycles/${cycleId}/reviews/for_reviewee/${reviewee_id}`;
        }
      },
      NON_MANAGER: {
        with(cycleId) {
          return `/performance_management/reviews/cycles/${cycleId}/reviews/non_manager`;
        }
      }
    },
    INCOMPLETE_MANAGER_REVIEWS: {
      with(cycleId) {
        return `/performance_management/reviews/cycles/${cycleId}/reviews/incomplete_for_managers`;
      }
    },
    REVIEW: {
      with(cycleId, reviewId) {
        return `/performance_management/reviews/cycles/${cycleId}/reviews/${reviewId}`;
      }
    },
    REVIEWS_RESULTS: {
      with(cycleId) {
        return  `/performance_management/reviews/cycles/${cycleId}/reviews/results`;
      }
    },
    REVIEWEE: {
      with(cycleId, revieweeId) {
        return `/performance_management/reviews/cycles/${cycleId}/reviewees/${revieweeId}`;
      },
      REVIEWS: {
        with(cycleId, revieweeId) {
          return `/performance_management/reviews/cycles/${cycleId}/reviewees/${revieweeId}/reviews`;
        }
      }
    },
    REVIEWEES: {
      ADMIN: {
        with(cycleId) {
          return `/performance_management/reviews/cycles/${cycleId}/reviewees`;
        }
      },
      TEAM: {
        with(cycleId) {
          return `/performance_management/reviews/cycles/${cycleId}/reviewees/team`;
        }
      },
      ALL: '/performance_management/reviews/reviewees'
    },
    TYPES: {
      with(cycleId) {
        return `/performance_management/reviews/cycles/${cycleId}/types`;
      }
    },
    CYCLE_NAVIGATION: {
      with(cycleId) {
        return `performance_management/reviews/cycles/${cycleId}/navigation`;
      }
    },
    NOTES: {
      NEW: {
        with(reviewId) {
          return `performance_management/reviews/reviews/${reviewId}/notes`;
        }
      }
    },
    NAVIGATION: '/performance_management/reviews/navigation',
    SCORED_QUESTIONS: '/performance_management/reviews/questions/scored'
  },

  SURVEYS: {
    NAVIGATION: '/surveys/surveys/navigation',
    NAVIGATION_FOR_SURVEY: {
      with(surveyId) {
        return `/surveys/surveys/${surveyId}/navigation_for_survey`;
      }
    },
    RESULTS: {
      with(surveyId) {
        return `/surveys/surveys/${surveyId}/results`;
      }
    },
    ANSWERS: {
      with({questionId, periodDateFilter, contentFilter}) {
        periodDateFilter = periodDateFilter || '';
        contentFilter = contentFilter || '';

        return `/surveys/questions/${questionId}/answers?period_date=${periodDateFilter}&content=${contentFilter}`;
      },
      WITHOUT_PAGINATION: {
        with({questionId, periodDateFilter, contentFilter}) {
          periodDateFilter = periodDateFilter || '';
          contentFilter = contentFilter || '';

          return `/surveys/questions/${questionId}/answers?period_date=${periodDateFilter}&content=${contentFilter}&paginate=false`;
        }
      },
    },
    RESPONSE: {
      with(surveyId) {
        return `/surveys/surveys/${surveyId}/responses/write`;
      },
      withEmployeeId(surveyId, employeeId) {
        return `/surveys/surveys/${surveyId}/responses/find_for_employee?employee_id=${employeeId}`;
      },
    },
    EMPLOYEE_STATUSES: {
      ALL: {
        with(surveyId) {
          return `/surveys/surveys/${surveyId}/employee_statuses`;
        }
      },
      with(employeeId) {
        return `employees/${employeeId}/survey_employee_statuses`;
      }
    },
    SURVEYS: '/surveys/surveys',
    SURVEYS_LAUNCHED: '/surveys/configured/non_anonymous',
    SURVEYS_ANONYMOUS: '/surveys/configured/anonymous',
    SURVEY: {
      with(id) {
        return `/surveys/surveys/${id}`;
      },
      CLOSE: {
        with(id) {
          return `/surveys/surveys/${id}/close`;
        }
      },
      RELAUNCH: {
        with(id) {
          return `/surveys/surveys/${id}/relaunch`;
        }
      },
      REOPEN: {
        with(id) {
          return `/surveys/surveys/${id}/reopen`;
        }
      }
    }
  },

  BENEFITS: {
    NAVIGATION: '/benefit_enrolments/navigation',
    BENEFIT_CLASSES: '/benefits/benefit_classes',
    BENEFIT_CLASS: {
      with(id) {
        return `/benefits/benefit_classes/${id}`;
      }
    },
    UPDATES: '/benefit_updates',
    BENEFIT_INFO_CHANGE_REQUESTS: '/benefit_info_change_requests',
    BENEFIT_INFO_CHANGE_REQUEST: {
      with(id) {
        return `/benefit_info_change_requests/${id}`;
      }
    },
    EMPLOYEE_COVERAGES: '/benefits/employee_coverages',
    INTERESTED: '/benefits/interested'
  },

  LEAVE_TYPES: '/leave_types',
  LEAVE_TYPE: {
    with(id) {
      return `/leave_types/${id}`;
    }
  },

  EMPLOYEE_ONBOARD: {
    EMPLOYEE_INFO: {
      with(id) {
        return `/employees/${id}/onboard_employee_info`;
      }
    },
    EMERGENCY_INFO: {
      with(id) {
        return `/employees/${id}/onboard_emergency_info`;
      }
    },
    BANKING_INFO: {
      with(id) {
        return `/employees/${id}/bank_info/update_for_onboarding`;
      }
    },
  },

  BANKING_INFO: {
    with(id) {
      return `/employees/${id}/bank_info`;
    }
  },

  INTEGRATIONS: {
    EXTERNAL_CANDIDATES: {
      with(source) {
        return `/integrations/external_candidates?source=${source}`;
      }
    },
    EXTERNAL_CANDIDATES_SYNC: '/integrations/external_candidates/sync',
    EXTERNAL_CANDIDATES_SYNC_STATUS: '/integrations/external_candidates/sync_status',
    GREENHOUSE_GENERATE_KEY: '/integrations/greenhouse/generate_key',
    GREENHOUSE_DELETE_KEY: '/integrations/greenhouse/delete_key'
  },

  SLACK: {
    DISCONNECT_USER: '/slack/disconnect_user',
    DISCONNECT_WORKSPACE: '/slack/disconnect_workspace'
  },

  USER_API_KEYS: {
    ALL: '/user_api_keys',
    with(apiKeyId) {
      return `user_api_keys/${apiKeyId}`;
    }
  },

  EMPLOYEE_CUSTOM_FIELDS: {
    HIRING: {
      with(employeeId) {
        return `/employee_custom_fields/hiring?employee_id=${employeeId}`;
      }
    },
    ENROLMENT: '/employee_custom_fields/enrolment',
    EMPLOYMENT_RECORD: {
      with({employeeId, locationId, departmentId, employmentType}) {
        departmentId = departmentId || '';

        return `/employee_custom_fields/employment_record?employee_id=${employeeId}&location_id=${locationId}&department_id=${departmentId}&employment_type=${employmentType}`;
      },
    },
    LIST_EMPLOYMENT_RECORD_CUSTOM_FIELDS_FOR_EMPLOYEE: {
      with(employeeId) {
        return `/employee_custom_fields/list_employment_record_custom_fields_for_employee?employee_id=${employeeId}`;
      }
    }
  },

  HR: {
    INTERESTED: '/hr/interested'
  },

  PA_DASHBOARD: {
    COMPANY_CONTACTS: '/pa_dashboard/company_contacts',
    COMPANY_PLANS: '/pa_dashboard/company_plans',
    FORMS: '/pa_dashboard/forms',
    KEY_DATES: '/pa_dashboard/key_dates',
    OUTSTANDING_ENROLMENTS: '/pa_dashboard/outstanding_enrolments'
  },

  HR_DASHBOARD: {
    CELEBRATIONS: '/hr_dashboard/celebrations',
    STAFF_COUNTS: '/hr_dashboard/staff_counts',
    INSIGHTS: {
      INBOX_SUMMARY: '/hr_dashboard/insights/inbox',
      PTO_SUMMARY: '/hr_dashboard/insights/pto',
      JOB_APPLICANT_SUMMARY: '/hr_dashboard/insights/job_applicant',
      NEW_HIRE_SUMMARY: '/hr_dashboard/insights/new_hire'
    }
  },

  BROKER_PORTAL: {
    BROKER_INVITATIONS: '/broker_portal/broker_invitations',
    COMPANY: {
      with(id) {
        return `/broker_portal/companies/${id}`;
      }
    },
    COMPANIES: '/broker_portal/companies',
    DASHBOARD: {
      COMPANIES: '/broker_portal/dashboard/companies',
      COMPANIES_BY_CARRIER: '/broker_portal/dashboard/companies_by_carrier',
      EMPLOYEES_BY_CARRIER: '/broker_portal/dashboard/employees_by_carrier',
      UPCOMING_RENEWAL_DATES: '/broker_portal/dashboard/upcoming_renewals'
    },
    EMPLOYEE_DIRECTORY: '/broker_portal/employee_directory',
    BENEFIT_CLASS: {
      with(id) {
        return `/broker_portal/benefit_classes/${id}`;
      }
    },
    BENEFIT_ENROLMENTS: {
      ALL: '/broker_portal/benefit_enrolments',
      withFilter(companyId) {
        return `/broker_portal/benefit_enrolments?filter[company_id]=${companyId}`;
      }
    },
    BENEFIT_UPDATES: {
      all: '/broker_portal/benefit_updates',
      withCompany(companyId) {
        return `/broker_portal/benefit_updates/${companyId}/company`;
      },
      withFilter(companyId) {
        return `/broker_portal/benefit_updates?filter[company_id]=${companyId}`;
      }
    },
    BROKER_USERS: '/broker_portal/broker_users',
    GROUP_BENEFIT_COVERAGES: {
      with(benefitClassId) {
        return `/broker_portal/benefit_classes/${benefitClassId}/group_benefit_coverages`;
      }
    },
    OUTSTANDING_ENROLMENTS: {
      withFilter(companyId) {
        return `/broker_portal/outstanding_enrolments?filter[company_id]=${companyId}`;
      }
    },
    ENROLMENT_SUMMARIES: {
      withFilter(companyId) {
        return `/broker_portal/enrolment_summaries?filter[company_id]=${companyId}`;
      }
    },
    USERS: '/broker_portal/users',
    USER: {
      with(id) {
        return `/broker_portal/users/${id}`;
      }
    },
    CHANGE_PASSWORD: '/broker_portal/users/password',
    IMPERSONATE_COMPANY: {
      with(id) {
        return `/broker_portal/impersonate_companies/${id}`;
      }
    },
    STOP_IMPERSONATING: '/broker_portal/impersonate_companies/stop_impersonating',
    BROKER_CLIENTS: '/broker_portal/broker_clients',
    BROKER_CLIENT: {
      with(id) {
        return `/broker_portal/broker_clients/${id}`;
      }
    },
    INSURANCE_CARRIER_REPS: '/broker_portal/insurance_carrier_reps',
    CLIENTS: '/broker_portal/clients',
    MY_ACCOUNTS: {
      ALL: '/broker_portal/my_accounts',
      ACTIVATE: {
        with(accountId) {
          return `/broker_portal/my_accounts/${accountId}/activate`;
        }
      }
    },
    COMPANY_INSURANCE_CARRIERS: {
      with(companyId) {
        return `/broker_portal/companies/${companyId}/company_insurance_carriers`;
      }
    },
    REGIONS: {
      with(countryCode) {
        return `/broker_portal/countries/${countryCode}/regions`;
      }
    },
    REPORTS: {
      with(companyId, reportType) {
        return `/broker_portal/companies/${companyId}/reports/${reportType}`;
      }
    },
    REPORT_DOWNLOAD: {
      with(companyId, reportId) {
        return `/broker_portal/companies/${companyId}/report_downloads/${reportId}`;
      }
    },
    CURRENT_BROKER: '/broker_portal/brokers/current',
    SECURE_UPLOAD: {
      PRESIGNER: {
        with(modelType) {
          return `/broker_portal/secure_upload/presigner?modelType=${modelType}`;
        }
      }
    }
  },

  BROKER: {
    withKey(key) {
      return `/brokers/find?key=${key}`;
    }
  },

  CURRENT_BROKER: '/brokers/current',

  MY_ACCOUNTS: {
    ALL: '/my_accounts',
    ACTIVATE: {
      with(accountId) {
        return `/my_accounts/${accountId}/activate`;
      }
    }
  },

  USER_INVITATIONS: {
    SIGNUP: '/user_invitations/signup',
    VALIDATE_INVITATION: '/user_invitations/validate',
    FIND_INVITATION: {
      with(token) {
        return `/user_invitations/find_invitation?token=${token}`;
      }
    }
  },

  ADMIN_INVITATION: {
    SIGNUP: '/admin_invitations/signup',
    with(token) {
      return `/admin_invitations/${token}`;
    }
  },

  RECOVERY_CODES: '/recovery_codes',
  DRAFTS: {
    with(draftParams) {
      return `/drafts/find_by_draftable?draftable_id=${draftParams.id}&draftable_type=${draftParams.type}`;
    },
  },

  MASS_UPDATES: {
    ALL: '/mass_updates',
    with(id) {
      return `/mass_updates/${id}`;
    },
    EMPLOYEES: {
      with(massUpdateId, effectiveDate) {
        return `/mass_updates/${massUpdateId}/mass_update_employees${effectiveDate ? `?effective_date=${effectiveDate}` : ''}`;
      }
    }
  },

  NMBR: {
    PAY_SCHEDULES: {
      ALL: '/nmbr/pay_schedules',
      WITH_NEXT_PAYROLLS: '/nmbr/pay_schedules/list_with_next_payrolls?include=next_payrolls'
    },
    PAY_STUBS: {
      with(employeeId) {
        return `/nmbr/pay_stubs?employee_id=${employeeId}`;
      },
      DOWNLOAD: {
        with(payStubId) {
          return `/nmbr/pay_stubs/download?pay_stub_id=${payStubId}`;
        },
      }
    },
    UPDATES: {
      ALL: '/nmbr/updates'
    },
    EMPLOYEES: {
      ALL: '/nmbr/employees',
      BATCH_ADD_EMPLOYEES: '/nmbr/employees/batch_add',
    },
    SETUP: '/nmbr/setup',
    SIGN: '/nmbr/sign'
  }
};

export default endpoints;
