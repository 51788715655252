import {action, computed, observable} from 'mobx';
import _ from 'lodash';
/*global pdfjsLib*/

class EmployeeDocumentState {
  @observable document;

  @observable isUploading = false;
  @observable errors = {};

  receiveProps({document, errors}) {
    this.document = document;
    this.errors = errors;
  }

  @action updateFile(files) {
    const file = _.head(files);
    file ? this.mergeFile(file) : this.removeFile();
  }

  @action mergeFile(file) {
    this.document.merge({originalFile: file});
  }

  @action removeFile() {
    this.document.originalFile = null;
  }

  @action onUploadStarted() {
    this.isUploading = true;
  }

  @action async onUploadFinished() {
    if (this.document.documentType === 'pdf_template') {
      await this.validateEncryption();
    }
    this.isUploading = false;
  }

  @action async validateEncryption() {
    this.errors = {};
    if (!this.document.originalFile) return;

    pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.10.38/pdf.worker.min.mjs';

    try {
      await pdfjsLib.getDocument({url: this.document.originalFile.preview}).promise;
    } catch (error) {
      if (error.name === 'PasswordException') {
        this.errors = {
          awsFiles: 'Document is password protected. Password protected files are not supported for annotation.'
        };
      } else {
        throw error;
      }
    }
  }

  @computed get files() {
    return this.document.originalFile ? observable([this.document.originalFile]) : observable([]);
  }

  @computed get canSaveDocument() {
    return !!this.document.originalFile && !this.errors.awsFiles;
  }
}

export default EmployeeDocumentState;
