/*global pdfjsLib*/
/*global pdfjsViewer*/

const CANVAS_BORDER_WIDTH = 1;

function ensurePdfJs() {
  if (typeof pdfjsLib === 'undefined') {
    throw new Error('PDFJS is not defined. Add the following script to <head>: <script type="module" src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.10.38/pdf.min.mjs"></script type="module">');
  }

  if (typeof pdfjsViewer === 'undefined') {
    throw new Error('PDFJSViewer is not defined. Add the following script to <head>: <script type="module" src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.10.38/pdf_viewer.mjs"></script>');
  }
}

function setupMutationObserver() {
  const DEL_SELECTOR = '.annotationLayer section:not(.linkAnnotation)';

  const mutationObserver = new MutationObserver(onMutation);

  observe();

  function onMutation(mutations) {
    let stopped;
    for (const {addedNodes} of mutations) {
      for (const node of addedNodes) {
        if (node.tagName) {
          if (node.matches(DEL_SELECTOR)) {
            stopped = true;
            mutationObserver.disconnect();
            node.remove();
          } else if (node.firstElementChild && node.querySelector(DEL_SELECTOR)) {
            stopped = true;
            mutationObserver.disconnect();
            for (const el of node.querySelectorAll(DEL_SELECTOR)) el.remove();
          }
        }
      }
    }
    if (stopped) observe();
  }

  function observe() {
    mutationObserver.observe(document, {
      subtree: true,
      childList: true,
    });
  }
}

function setupEvents(pdfSinglePageViewer, eventBus, onPDFLoad, onPageChange){
  eventBus.on("pagesinit", function () {
    pdfSinglePageViewer.currentScaleValue = (document.getElementById("pdfViewerContainer").clientWidth /
      (pdfSinglePageViewer._pages[0].width + 2 * CANVAS_BORDER_WIDTH));

    onPDFLoad();
  });

  eventBus.on("pagechanging", function (e) {
    onPageChange(e.pageNumber);
  });
}

function setupLinkService(eventBus, pdf){
  const pdfLinkService = new pdfjsViewer.PDFLinkService({
    eventBus,
  });

  pdfLinkService.externalLinkTarget = pdfjsViewer.LinkTarget.BLANK;
  pdfLinkService.setDocument(pdf, null);

  return pdfLinkService;
}

function setupViewer(eventBus, pdf, pdfLinkService, container) {
  const pdfSinglePageViewer = new pdfjsViewer.PDFSinglePageViewer({
    container,
    eventBus,
    linkService: pdfLinkService,
  });

  pdfSinglePageViewer.renderInteractiveForms = false;
  pdfLinkService.setViewer(pdfSinglePageViewer);
  pdfSinglePageViewer.setDocument(pdf);

  return pdfSinglePageViewer;
}

function browserNotSupported() {
  const regex = /Version\/([0-9]|(1[0-3]))\.(\d+)(?:\.(\d+))?.*Safari/;

  if (typeof pdfjsLib === 'undefined' && regex.test(window.navigator.userAgent)) return true;
}

async function renderPdf({url, containerId, onPDFLoad={}, onPageChange={}}) {
  if (browserNotSupported()) {
    return {
      errors: {
        browserNotSupported: 'components.pdf.Viewing PDFs in Collage is not supported for this version of your browser. Please upgrade your browser or download the file.'
      }
    };
  }

  ensurePdfJs();

  setupMutationObserver();

  pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.10.38/pdf.worker.min.mjs';

  let pdf;
  try {
    pdf = await pdfjsLib.getDocument({url}).promise;
  } catch (error) {
    if (error.name === 'PasswordException') {
      return {
        errors: {
          passwordProtected: error.message
        }
      };
    } else {
      throw error;
    }
  }

  const container = document.getElementById(containerId);
  if (!container) return;

  const eventBus = new pdfjsViewer.EventBus();

  const pdfLinkService = setupLinkService(eventBus, pdf);

  const pdfSinglePageViewer = setupViewer(eventBus, pdf, pdfLinkService, container);

  setupEvents(pdfSinglePageViewer, eventBus, onPDFLoad, onPageChange);

  return {pdf, viewer: pdfSinglePageViewer};
}

export default renderPdf;
