import {DomainObject, oneOf} from 'shared/store';
import {action, observable, computed} from 'mobx';
import {User} from '../users';
import {Employee} from '../employees';
import {calendarDate} from 'shared/tools';
import _ from 'lodash';

class NmbrUpdate extends DomainObject {
  @observable employee;
  @observable fieldUpdates;
  @observable retroactivePayUpdate;
  @observable createdByUser;
  @observable createdAt;
  @observable effectiveDate;
  @observable eventType;

  @action merge(other) {
    super.merge(other, {
      eventType: oneOf([
        'employee_added',
        'employee_updated',
        'employment_record_created',
        'employment_record_updated',
        'employment_record_destroyed',
        'on_leave_record_created',
        'on_leave_record_updated',
        'on_leave_record_destroyed',
        'termination_record_created',
        'termination_record_destroyed',
        'reactivation_record_created',
        'reactivation_record_updated',
        'reactivation_record_destroyed',
        'return_from_leave_record_created',
        'return_from_leave_record_updated',
        'return_from_leave_record_destroyed',
        'tax_form_completed',
        'employee_removed',
        'province_of_employment_updated'
      ]),
      _dates: ['effectiveDate', 'createdAt'],
      createdByUser: User,
      employee: Employee
    });
  }

  @computed get effectiveDateView() {
    return calendarDate(this.effectiveDate);
  }

  @computed get createdAtView() {
    return calendarDate(this.createdAt);
  }

  hasFieldUpdate(updateType) {
    return this.fieldUpdatesForType(updateType).length > 0;
  }

  fieldUpdatesForType(updateType) {
    return _.filter(this.fieldUpdates, {updateType});
  }
}

export default NmbrUpdate;
