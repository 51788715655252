import React from 'react';
import {observer} from 'mobx-react';
import {t} from 'shared/core';
import {QuickTip, Table} from 'components';
import _ from 'lodash';
import {FormattedMessage} from 'react-intl';

const COLUMNS = [
  {
    header: 'nmbr.updates.directory.Field',
    width: 6,
    render: model => t(`nmbr.updates.directory.fields.${model.field}`)
  },
  {
    header: 'nmbr.updates.directory.Value',
    width: 6,
    attribute: 'value'
  }
];

const EmployeeTable = observer(({nmbrUpdate}) => {
  if (!(nmbrUpdate.hasFieldUpdate('field') || nmbrUpdate.hasFieldUpdate('missing_fields') || nmbrUpdate.hasFieldUpdate('nmbr_validation_error'))) return null;

  return (
    <div className='space-y'>
      <div className='h3 medium'><FormattedMessage id='nmbr.updates.directory.Employee Fields'/></div>
      {nmbrUpdate.hasFieldUpdate('field') && <Table
        columns={COLUMNS}
        models={_.filter(nmbrUpdate.fieldUpdates, {updateType: 'field'})}
        showLinks={false}
      />}
      {nmbrUpdate.hasFieldUpdate('missing_fields') && <QuickTip
        trait='error'
        header={t('nmbr.updates.directory.The following required fields have not been filled out for this employee')}
        paragraphs={[
          <ul>
            {nmbrUpdate.fieldUpdatesForType('missing_fields')[0].fields.map(field => <li className='ml2'>
              <FormattedMessage id={`nmbr.employees.directory.missing_fields.${field}`}/>
            </li>)}
          </ul>,
          <FormattedMessage id='nmbr.updates.directory.You may not be able to run payroll for this employee until these fields are updated.'/>
        ]}/>}
      {nmbrUpdate.hasFieldUpdate('nmbr_validation_error') && <QuickTip
        trait='error'
        header={t('nmbr.updates.directory.Some fields could not be processed')}
        paragraphs={[
          <ul>
            {nmbrUpdate.fieldUpdatesForType('nmbr_validation_error')[0].errors.map(error => <li className='ml2'>
              {error}
            </li>)}
          </ul>,
          <FormattedMessage id='nmbr.updates.directory.You may not be able to run payroll for this employee until these fields are updated.'/>
        ]}/>}
    </div>
  );
});

export default EmployeeTable;
